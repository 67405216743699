<template>
  <div class="recentlyRead topmargin">
    <topNav title="最近閱讀" :border="true"></topNav>
    <!-- 8本推薦位 -->
    <eightShelf
      :shelf="shelf1"
      title="猜你喜歡"
      shelfid="yuanshi_wap_people_like"
    ></eightShelf>
    <!-- 歷史記錄 -->
    <div class="ShelfRecommend normalBox">
      <h3 class="clearfix">
        <div class="shelfName fl clearfix">
          <span class="left-line fl"></span>
          <p class="fl">最近閱讀</p>
        </div>
      </h3>
      <ul
        class="bookmore"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loading"
        infinite-scroll-distance="10"
      >
        <li v-for="item in historyList" class="clearfix" :key="item.id">
          <router-link class="fl moreimg" :to="'/book/' + item.id">
            <img v-lazy="item.iconUrlMedium" alt="" />
          </router-link>
          <div class="fl moreInfo">
            <router-link :to="'/book/' + item.id" class="name">{{
              item.name
            }}</router-link>
            <router-link
              :to="'/book/' + item.id"
              v-if="item.newChapter"
              class="chapterName"
              >{{ item.newChapter.name }}</router-link
            >
            <div class="clearfix oldName">
              <span class="fl" v-if="item.historyChapter">{{
                item.historyChapter.name
              }}</span>
              <router-link
                :to="'/book/' + item.id + '/' + item.historyChapter.id"
                class="fr"
                v-if="item.historyChapter"
                >繼續閱讀 ></router-link
              >
            </div>
          </div>
        </li>
      </ul>
      <div v-if="showMore" class="nonehint">
        <img src="~img/account/nonehint.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { moreshelf } from "apiurl/book";
import { readhistory } from "apiurl/user";
export default {
  data() {
    return {
      shelf1: [],
      historyList: [],
      loading: false,
      showMore: false,
      pageNum: 0,
      hasNextPage: true
    };
  },
  created() {
    this.$nextTick(() => {
      this.getmoreshelf();
    });
  },
  methods: {
    getmoreshelf() {
      moreshelf({ shelfId: "yuanshi_wap_people_like" }).then(res => {
        if (res.success) {
          this.shelf1 = res.data.items;
          this.shelf1.forEach(item => {
            item.introduce = item.introduce.substring(0, 30) + "...";
          });
        }
      });
    },
    async loadMore() {
      if (this.hasNextPage) {
        this.loading = false;
        this.pageNum++;
        let res = await readhistory({ pageNum: this.pageNum, pageSize: 10 });
        if (res.success) {
          if (!this.isAllSelect) {
            res.data.items.forEach(item => {
              item.select = true;
            });
          } else {
            res.data.items.forEach(item => {
              item.select = false;
            });
          }

          this.hasNextPage = res.data.hasNextPage;
          this.historyList = [...this.historyList, ...res.data.items];
        }
      } else {
        this.loading = true;
        this.showMore = true;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.bookmore
    li
        padding-bottom 30px
        border-bottom 2px solid #eee
        margin-bottom 30px
        .moreimg
            width 171px
            height 239px
            margin-right 27px
            background rgba(242, 242, 242, 1)
            box-shadow 0px 2px 4px 0px rgba(0, 0, 0, 0.2)
            border-radius 8px
            img
                width 100%
                border-radius 8px
        .moreInfo
            width 500px
            a
                display block
            .name
                font-size 28px
                margin-bottom 18px
                margin-top 10px
            .chapterName
                color rgba(153, 153, 153, 1)
                line-height 33px
                font-size 24px
                margin-bottom 90px
            .oldName
                span
                    display inline-block
                    line-height 46px
                a
                    width 117px
                    height 46px
                    border-radius 8px
                    border 2px solid rgba(238, 238, 238, 1)
                    color #2189F2
                    font-size 20px
                    line-height 46px
                    text-align center
.nonehint
    margin 65px auto 30px
    width 209px
    img
        width 209px
</style>
